import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/pjlyWdwR_fU">
    <SEO title="Love in Action - Love Songs" />
  </Layout>
)

export default SermonPost
